import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import BlockContent from "../components/block-content";
import Layout from "../components/layout.js";

export const query = graphql`
  query ThemesPage {
    sanityPage(_id: { eq: "a6e39fdc-cf54-4728-a549-a7411e402df5" }) {
      id
      title
      description
      _rawBody
      callToAction {
        href
        linkText
      }
    }
  }
`;

export default function ThemesPage({ data }) {
  const page = data.sanityPage;

  return (
    <Layout title={page.title} description={page.description}>
      <BlockContent blocks={page._rawBody || []} />
      <Link class="action" to={page.callToAction.href}>
        {page.callToAction.linkText}
      </Link>
    </Layout>
  );
}
